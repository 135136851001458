<template>
<!-- 用户设置  组件 -->
  <div
    class="bg-white px-3"
    style="margin: -10px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <el-button type="success" size="mini" @click="dialogVisible = true"
        >增加人员</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="usealias" label="用户名" width="180" />
      <el-table-column prop="username" label="手机号" width="180" />
      <el-table-column label="操作" width="300" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" plain @click="edituser(scope.row.id)"
              >修改</el-button
            >
            <el-button size="small" plain type="danger">删除</el-button>
            <el-button size="small" plain  @click="clickproject(scope.row.id)">项目权限</el-button>
             <el-button size="small" plain  @click="clickuseroperate(scope.row.id)">操作权限</el-button>
          </el-button-group>
          
        </template>
      </el-table-column>
    </el-table>
  </div>
  <!-- 新增用户 -->
  <el-dialog v-model="dialogVisible" title="新增用户" width="30%">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="用户名：">
        <el-input v-model="formInline.usealias"></el-input>
      </el-form-item>
      <el-form-item label="手机号:">
        <el-input v-model="formInline.username"></el-input>
      </el-form-item>
      <el-form-item label="密码:">
        <el-input v-model="formInline.password"></el-input>
      </el-form-item>
      <el-form-item label="权限:">
        <el-input v-model="formInline.super"></el-input
        ><span>0-超级用户 1-高级用户 2-操作用户</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 添加项目权限 -->
  <el-dialog v-model="dialogVisibleup" title="添加项目权限" width="30%">
    <user-project :manager_id="manager_id" v-if="dialogVisibleup"></user-project>
  </el-dialog>
  <!-- 添加用户操作权限 -->
    <el-dialog v-model="dialogVisibleuo" title="添加用户操作权限" width="50%">
    <user-operate :manager_id="manager_id" v-if="dialogVisibleuo"></user-operate>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
import userproject from "./userproject.vue";
import useroperate from "./useroperate.vue";
export default {
  data() {
    return {
      manager_id:"", //选中的用户ID
      tableData: [],
      dialogVisible: false, //控制新增用户对话框关闭
      dialogVisibleup: false, //控制项目权限对话框关闭
      dialogVisibleuo:false,//控制项操作权限对话框关闭
      formInline: {
        password: "",
        usealias: "",
        username: "",
        super: 2, //0-超级用户  1-高级用户  2-操作用户
        role_id: 1,
        status: 1,
      },
    };
  },
  created() {
    this.loadtabledata();
  },
  methods: {
    //单击设置项目权限
    clickproject(manager_ids){
      this.dialogVisibleup=true
      this.manager_id = manager_ids
    },
    //单击设置用户操作权限
    clickuseroperate(manager_ids){
      this.dialogVisibleuo=true
      this.manager_id = manager_ids
    },
    //点击确定保存新增用户按钮
    onSubmit() {
      //保存新增数据到数据库中
      this.adduser(); //新增用户
    },
    //新增用户
    adduser() {
      axios
        .post("admin/manager", this.formInline, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });

          this.dialogVisible = false;
          this.loadtabledata(); //重新加载用户列表
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },

    //加载用户列表
    loadtabledata() {
      axios
        .get("admin/manager/1", {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.data.list, "******");
          this.tableData = response.data.data.list;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    edituser(rowid) {
      console.log(rowid);
    },
  },
  components: {
    "user-project": userproject,
    "user-operate":useroperate
  },
};
</script>


<style>
</style>
<template>
<!-- 用户项目权限设置  组件 -->
  <div
    class="bg-white px-3"
    style="margin: -10px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <el-tree
      ref="el-tree"
      :data="projectdatalist"
      :props="defaultProps"
      show-checkbox
      node-key="id"
      :default-checked-keys="userprojectlist"
      @check="check"
    />
  </div>
  <el-button type="primary" @click="onSubmitup">保存</el-button>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  props: ["manager_id"],
  data() {
    return {
      //设置项目权限
      formInline: {
        project_ids: [],
      },
      //所有项目列表
      projectdatalist: [],
      //tree props 参数
      defaultProps: {
        label: "name",
      },
      //用户原有项目权限 数据
      userprojectlist: [],
    };
  },
  created() {
    //获取用户原有项目权限
    this.loaduserproject();
    //加载所有项目列表
    this.loadprojectlistm();
    
  },
  methods: {
    //确定更改权限设置
    onSubmitup() {
      this.formInline.project_ids = this.userprojectlist;
      this.adduserproject();
    },

    //设置用户项目权限
    adduserproject() {
      axios
        .post(
          "admin/manager/" + this.manager_id + "/set_project",
          this.formInline,
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //获取用户原有项目权限
    loaduserproject() {
      this.axios
        .get("admin/ManagerProject", {
          params: {
            manager_id: this.manager_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          //默认原有权限
          this.userprojectlist = response.data.map((item) => item.project_id);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },

    //加载所有项目列表
    loadprojectlistm() {
      this.axios
        .get("admin/Project/1", {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("llllllllllllllllllss", response.data);
          this.projectdatalist = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //
    check(...e) {
      console.log('check--',e[1].checkedKeys);
      this.userprojectlist = e[1].checkedKeys;
    },
    //
    edituser(rowid) {
      console.log(rowid);
    },
  },
};
</script>


<style>
</style>
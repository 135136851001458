<template>
  <el-card class="box-card">
    <el-form
      label-width="100px"
      :model="formLabelAlign"
      style="max-width: 460px"
      v-if="ishowaddform"
    >
      <el-form-item label="名称">
        <el-input v-model="formLabelAlign.name"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="formLabelAlign.porder"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="formLabelAlign.pdesc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>

    <el-divider></el-divider>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="name" label="名称" width="300" />
      <el-table-column prop="imgurl" label="图片" width="180">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)"
            >上传图片</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="porder" label="排序" width="180" />
      <el-table-column prop="pdesc" label="备注" />
    </el-table>
  </el-card>
  <el-button type="primary" plain @click="ishowaddform = !ishowaddform"
    >增加设置项目</el-button
  >

  <el-dialog v-model="dialogVisible" width="50%" @closed="loadprojectlist">
    <div class="text-center">
      <file-upload
        :fileclass="fileclass"
        :prdclassid="prdclassid"
        :urlpath="urlpath"
        filedir="projectpic"
      ></file-upload>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      //是否打开上传模态框
      dialogVisible:false,
      fileclass: "0", //分类ID 0-项目图片  1-付款合同  2-付款申请   3-发票   4-银行回单  
      prdclassid: "", //记录ID
      urlpath:"",//上传图片API接口地址

      //项目列表数据
      tableData: [],
      //新增表单数据
      formLabelAlign: {
        name: "",
        imgurl: "",
        porder: "",
        pdesc: "",
      },
      //控制是否显示增加表单
      ishowaddform: false,
    };
  },
  created() {
    //加载项目列表
    this.loadprojectlist();
  },
  methods: {
    //上传图片按钮单击
    handleEdit(indexval, rowid) {
      console.log(indexval, rowid);
      this.prdclassid = rowid.id;
      this.urlpath = 'admin/Project/' + rowid.id;
      this.dialogVisible = true;
    },
    //提交保存
    onSubmit() {
      //新增项目记录
      this.addcontract();
    },
    //加载项目列表
    loadprojectlist() {
      //加载项目列表
      this.axios
        .get("admin/Project/1", {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //新增项目记录
    addcontract() {
      axios
        .post("admin/Project", this.formLabelAlign, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
          //加载项目列表
          this.ishowaddform = false;
          this.loadprojectlist();
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>



<style>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
</style>

<template>
  <!-- 用户操作权限设置  组件 -->
  <div
    class="bg-white px-3"
    style="margin: -10px; margin-top: 1rem; margin-bottom: 0 !important"
  >
 <el-row>
    <el-col :span="12"><div class="grid-content bg-purple"> <el-tree
      :data="userprojectlist"
      :props="defaultProps"
      show-checkbox
      check-strictly
      node-key="id"
      @check-change="handleClick"
      ref="treeForm"
    /></div></el-col>
    <el-col :span="12"><div class="grid-content bg-purple-light ml-5"> <el-tree
      :data="allmenuslist"
      :props="menusProps"
      show-checkbox
      node-key="id"
      ref="treeForms"
      default-expand-all
      :check-strictly="true"
    /></div></el-col>
  </el-row>

   
  </div>
  <el-button type="primary" @click="setMenusbut">保存</el-button>
  
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
export default {
  props: ["manager_id"],
  data() {
    return {
      //选中的项目tree Node
      project_ids:"",

      //用户项目操作单菜列表
      usermenulist:[],
      
      //用户项目操作单菜排序数组
      menus_id:[],

      //用户项目操作单菜排序数组
      usermenuorders:[],

      //用户项目权限 数据
      userprojectlist: [],

      //所有菜单列表 数据
      allmenuslist: [],

      //项目tree props 参数
      defaultProps: {
        label: "name",
      },
      //菜单tree props 参数
      menusProps: {
        label: "name",
        children:"child"
      },
    };
  },
  created() {
    //获取用户原有项目权限
    this.loaduserproject();
    //获取所有菜单列表
    this.loadmenuslist();
  },
  methods: {
    //点击保存按钮
    setMenusbut(){
     let temparr = this.$refs.treeForms.getCheckedNodes();

     let menusids = temparr.map((item)=>item.id)
     let aa = {}
     temparr.map((item) =>{
       aa[item.id]=item.orders
      });

    if(this.project_ids==""){
      console.log("没有选中项目名称")
    }else{
      
      this.setusermenus(aa,menusids)
    }
    

    },
    //获取用户项目权限
    loaduserproject() {
      this.axios
        .get("admin/ManagerProject", {
          params: {
            manager_id: this.manager_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          //默认原有权限
          this.userprojectlist = response.data.map((item) => item.project);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //树列表实现单选
    handleClick(data, checked) {
      /* 主要通过checked进行判断 */
      if (checked) {
        let arr = [data.id];
        this.$refs.treeForm.setCheckedKeys(arr);
        this.project_ids = arr[0];
        this.loadusermenuslist();  //选中默认菜单
      }
    },
    //获取所有菜单列表
    loadmenuslist() {
      this.axios
        .get("admin/Menus", {
          params: { },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          //默认原有权限
          this.allmenuslist = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //获取用户对应项目的操作菜单
    loadusermenuslist() {
      this.axios
        .get("admin/ManagerMenus/indexuseroperate", {
          params: {
            project_id: this.project_ids,
            manager_id: this.manager_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
         
          this.usermenulist = res.data.data.map((item) => item.id);
          this.$refs.treeForms.setCheckedKeys(this.usermenulist);
          
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
     //设置用户项目权限 
    setusermenus(usermenuorders,menus_id) {
      axios
        .post(
          "admin/manager/" + this.manager_id + "/set_operater",
          {
            project_id:this.project_ids,
            usermenuorders:usermenuorders,
            menus_id: menus_id
          },
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("新增成功！", "提示", {
            confirmButtonText: "OK",
          });
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>


<style>
</style>
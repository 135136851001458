<template>
  <div>
    <el-container
      style="
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
      "
    >
      <el-header class="d-flex align-items-center" style="background: #545c64">
        <p class="h5 text-light mb-0 mr-auto">DC项目管控系统</p>
        <el-button
          type="primary"
          @click="bakdatabase"
          v-if="user.super == 1 || user.super == 0"
          >备份数据库</el-button
        >
        <el-button
          type="primary"
          @click="dialogVisible = true"
          v-if="user.super == 1 || user.super == 0"
          >新增项目</el-button
        >
        <el-button
          type="primary"
          @click="dialogVisibleuser = true"
          v-if="user.super == 1 || user.super == 0"
          >用户设置</el-button
        >
      </el-header>
      <el-main>
        <el-button
          v-for="item in projectlist"
          :key="item['project']['id']"
          class="card mt-3"
          @click="projectinfo(item)"
          style="
            width: 18rem;
            float: left;
            margin-left: 5px;
            padding: 5px 5px 5px 5px;
          "
        >
          <el-image
            class="card-img-top"
            :src="item['project']['imgurl']"
            style="width: 275px; height: 200px"
          ></el-image>

          <h5 class="card-title ellipsis">
            {{ item["project"]["name"] }}
          </h5>
        </el-button>
      </el-main>
    </el-container>
  </div>

  <el-dialog v-model="dialogVisible" width="50%" @closed="loadprojectlist">
    <div class="text-center">
      <set-setproject></set-setproject>
    </div>
  </el-dialog>
  <el-dialog v-model="dialogVisibleuser" width="50%" @closed="loadprojectlist">
    <div class="text-center">
      <set-user></set-user>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import setproject from "../../components/project/setproject.vue";
import usersetVue from "../../components/manager/useset.vue";
export default {
  data() {
    return {
      projectlist: [],
      dialogVisible: false, //是否打开新增项目模态框
      dialogVisibleuser: false, //是否打开用户设置模态框
    };
  },
  created() {
    this.loadprojectlist();
  },
  methods: {
    // 备份数据库
    bakdatabase() {
      this.axios
        .get("admin/AccessoryFile/getbakdatabase", {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
          responseType: "blob", //改参数会以blob流格式返回
        })
        .then((response) => {
          let blob = new Blob([response.data]);
          let url = URL.createObjectURL(blob);
          //下载该blob链接
          let d = new Date();
          let year = d.getFullYear();
          let month = d.getMonth() + 1;
          let day = d.getDate();
          this.downloadFile(
            url,
            year + "-" + month + "-" + day + "-" + Date.now() + ".sql"
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },

    //下载备份的数据库文件方法
    downloadFile(downUrl, fileName) {
      const aLinkUrl = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      aLinkUrl.href = downUrl;
      aLinkUrl.download = fileName;
      const clickAlink = (obj) => {
        //模拟点击
        const ev = document.createEvent("MouseEvents");
        ev.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        obj.dispatchEvent(ev);
      };
      clickAlink(aLinkUrl);
    },

    projectinfo(project_ids) {
      console.log(project_ids["project"]);
      window.sessionStorage.setItem(
        "project_ids",
        project_ids["project"]["id"]
      );
      window.sessionStorage.setItem(
        "project_name",
        project_ids["project"]["name"]
      );
      window.sessionStorage.setItem(
        "project_imgurl",
        project_ids["project"]["imgurl"]
      );
      this.$router.push("/index");
    },
    //加载项目清单
    loadprojectlist() {
      this.axios
        .get("admin/ManagerProject", {
          params: {
            manager_id: this.user.id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          this.projectlist = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  components: {
    "set-setproject": setproject,
    "set-user": usersetVue,
  },
};
</script>

<style>
.ellipsis{
   margin-top: 15px;
   width: 275px;
   white-space: nowrap; /* 确保文本在一行内显示 */
   overflow: hidden; /* 超出部分隐藏 */
   text-overflow: ellipsis; /* 使用省略号表示文本超出 */
}
</style>
